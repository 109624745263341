* {
color: #4DB6AC;
background-color:  #0F172A;
font-family: "Segoe UI", Roboto, "Helvetica Neue";
}

.text-selected {
color: #E2E8F0 !important;
}

.text-normal {
color: #4DB6AC;
}

.error {
color: red;
}

.tag {
background-color: #21325C;
color: #4DB6AC;
border-radius: 7px;
}

.card {
  color: #4DB6AC;
  background-color:  #0F172A !important;
}